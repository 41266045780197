export const LoginErrors = {
    'E_UNKNOWN_USER': 'Sorry, that account couldn\'t be found! Check your details and try again.',
    'E_DATABASE_ERROR': 'Oops! Some kind of database error occurred!',
    'E_INVALID_PARAMS': 'Apparently the parameters are invalid! Did you leave a field blank?',
    'E_PASSWORD_HASH_ERROR': 'Somehow the backend failed to run the password hash check. You don\'t even need to know what that means, probably try again or just tell me.',
    'E_USER_NOT_APPROVED': 'This account has not been activated, please wait for an admin to approve it.',
    'E_USER_BANNED': 'Sorry, This account has been banned!'
};

export const ImageUploadErrors = {
    'E_DATABASE_ERROR': 'Oops! Some kind of database error occurred!',
    'E_INVALID_PARAMS': 'Apparently the parameters are invalid! Did you leave a field blank?',
    'E_SERVER_ERROR'  : 'Uh oh! An error occurred on the server!'
}