



















import { Vue, Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Logo, Textfield, Spinner } from '@components/UI';
import { LoginErrors } from '@shared/config/errors';

@Component({
    components: { Logo, Textfield, Spinner }
})
export default class ForgotPassView extends Vue {
    private resetCode: string = '';
    private password: string = '';
    private repassword: string = '';

    private loading: boolean = false;
    private error: string = '';
    private errorCode: string = '';

    @Action
    private resetPass: (any) => Promise<any>;

    private async onSubmit(): Promise<any> {
        if (this.canSubmit) {
            try {
                this.loading = true;
                const response = await this.resetPass({ code: this.resetCode, password: this.password});

                this.loading = false;
                this.error = '';

                (this as any).$snotify.success("Your password has been reset successfully.","Password Reset", {
                    timeout: 5000,
                    icon: "https://cdn4.iconfinder.com/data/icons/social-messaging-ui-color-and-shapes-5/177800/203-512.png"
                });
                this.password = "";
                this.resetCode = "";
                this.repassword = "";

                this.$router.push('/');
            } catch (error) {

                console.log(error);

                this.loading = false;
                this.errorCode = error.errorCode;
                this.error = error.error;
            }
        }
    }

    private get errorMessage(): string | null {
        if (this.errorCode) {
            if (LoginErrors[this.errorCode])
                return LoginErrors[this.errorCode];
            
            return this.error;
        }
        
        return null;
    }

    private get canSubmit(): boolean {
        return this.resetCode.length > 0 && this.password.length > 0 && this.password == this.repassword;
    }
}
